import React from 'react';

function NotFound() {
  return (
    <h1>
      Not found{' '}
      <span role="img" aria-label="crying face">
        😢
      </span>
    </h1>
  );
}

export default NotFound;
